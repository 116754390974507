export const POP_SECTIONS = {
    base: "base",
    qna: "qna",
    refund: "refund",
    close_redirect: "close_redirect"
}

export const REDIRECT_CONSTANTS = {
    end: "_end",
    close: "_close",

    refund: "refund"
}
