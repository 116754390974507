import React from 'react';
import { Link } from 'react-router-dom';

import './button.scss';

const ButtonOne = ({text,path, _callback, className = "", ref = null}) => {
  return (
        <Link to={path} ref={ref} className={`btn-action ${className}`} onClick={_callback} >
            {text}
        </Link>
  );
}

export default ButtonOne;