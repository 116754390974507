import React, { useState } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectableImage = React.memo(({ id, updateSelected }) => {

    const [selected, setSelected] = useState(false)

    const handleSelect = (state) => {
        updateSelected(id)
        setSelected(state)
    }

  return (
    <div className="position-relative overflow-hidden mx-auto my-2" style={{ width: "45%", borderRadius: 5 }} onClick={() => handleSelect(!selected)}>
        {
            selected && <div className="position-absolute d-flex justify-content-center align-items-center top-0 left-0 w-100 h-100" style={{
                background: "#080808b8"
            }}>
                {/* <span>Selected</span> */}
                <FontAwesomeIcon icon={faCheck} />
            </div>
        }
      <img
        className="h-100 w-100 user-select-none"
        style={{ objectFit: "cover" }}
        src={`https://api.artfactory.live/image/${id}`}
      />
    </div>
  );
});

export default SelectableImage