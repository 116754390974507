import React, {createContext, useState} from "react";

export const DataContext = createContext()

export default function DataContextProvider(props) {
    const languageCheck = localStorage.getItem("language")
    const [language, setLanguage] = useState(!!languageCheck ? languageCheck : "en")
    const existingLogin = localStorage.getItem("userCredentials")
    const [user, setUser] = useState(() => {
        return !!existingLogin ? JSON.parse(existingLogin) : false
    })

    return (

        <DataContext.Provider value={{
            user,
            setUser,
            language,
            setLanguage

        }}>
            {props.children}
        </DataContext.Provider>
    )
}