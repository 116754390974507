import React, { useContext } from "react";

import PopThingy from "../pop-thingy/PopThingy";

import "./_left-bottom-wrapper.scss";

const LeftBottomWrapper = () => {
  return (
    <div
      className="left-bottom-wrapper"
      style={{
        zIndex: 999,
      }}
    >
      <PopThingy initShown={true}/>
    </div>
  );
};

export default LeftBottomWrapper;
